import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { ActionButton, Disqus, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { site, videoFallback } from '@variables';

const UndergradProgramPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader title="SoIT - Undergraduate Program" />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row className="justify-content-center">
      {/* <Col md={6}>
          <ResponsiveEmbed aspectRatio="16by9" className="mb-3">
            <iframe
              title="Learn More about the School of Information Technology at UC"
              src={
                videoFallback ?
                  `` :
                  `https://player.vimeo.com/video/533214344`
              }
              className="embed-responsive-item"
              allow="autoplay; fullscreen"
              allowFullScreen />
          </ResponsiveEmbed>
        </Col> */}
      <Col md={8}>
        <ResponsiveEmbed aspectRatio="16by9" className="mb-3">
          <iframe
            title="Hear from an Advisor in the School of Information Technology"
            src={
              videoFallback ?
                `` :
                `https://player.vimeo.com/video/698242622?h=89949734be`
            }
            className="embed-responsive-item"
            allow="autoplay; fullscreen"
            allowFullScreen />
        </ResponsiveEmbed>
      </Col>
    </Row>
    <Row className="justify-content-center mb-3">
      <a
        href="https://kahoot.it/challenge/01172241?challenge-id=7fd3a198-e6bc-4e0d-98e8-8da1dff2d23b_1649430143679"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ActionButton>Play the IT Expo Kahoot Game</ActionButton>
      </a>
      <a
        // eslint-disable-next-line max-len
        href="https://outlook.office365.com/owa/calendar/MeetwithstudentsinterestedinCollege@mailuc.onmicrosoft.com/bookings/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ActionButton>Schedule Appointment with IT Enrollment Advisor</ActionButton>
      </a>
    </Row>
    <Row className="mb-3">
      <Col>
        <h2 className="text-center">Program Info Sheets</h2>
        <Row className="justify-content-center">
          <a
            href="https://cech.uc.edu/futurestudents/undergraduate_program_information.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActionButton>English</ActionButton>
          </a>
          <a href="https://cech.uc.edu/futurestudents/spanish_undergraduate_program_information.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActionButton>Spanish</ActionButton>
          </a>
        </Row>
      </Col>
      <Col>
        <h2 className="text-center">Curriculum Plans</h2>
        <Row className="justify-content-center">
          <a
            href="https://cech.uc.edu/schools/it/undergrad-programs/bachelorsofscienceininformationtechnology.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActionButton>BS IT</ActionButton>
          </a>
          <a
            href="https://cech.uc.edu/schools/it/undergrad-programs/bachelor-cybersecurity.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActionButton>BS Cyber</ActionButton>
          </a>
        </Row>
      </Col>
    </Row>
    <Disqus
      identifier="about-undergrad-2022"
      title="SoIT - Undergraduate Program"
      pageLocation={`${site.url}/about/undergrad`}
    />
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default UndergradProgramPage;
